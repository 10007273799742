<template>
  <div>
    <a-row :gutter="24" type="flex">
      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Walkathon List
                  <a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
              <a-col :span="24" :md="12" class="top-right-header">
                <router-link to="/walkathon-create">
                  <a-button>Add New</a-button>
                </router-link>
              </a-col>
            </a-row>
          </template>
          <a-table
            :columns="columnData"
            :data-source="walkathonList"
            :pagination="false"
            :scroll="{ x: true }"
          >
            <template slot="status" slot-scope="row">
              <div>{{ row.status ? "Active" : "Inactive" }}</div>
            </template>
            <template slot="location" slot-scope="row">
              <div
                style="cursor: pointer"
                @click="showLocationModal(row.locations.data)"
              >
                <svg
                  width="30"
                  height="30"
                  style="color: #b0e41c"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M18,4.48a8.45,8.45,0,0,0-12,12l5.27,5.28a1,1,0,0,0,1.42,0L18,16.43A8.45,8.45,0,0,0,18,4.48ZM16.57,15,12,19.59,7.43,15a6.46,6.46,0,1,1,9.14,0ZM9,7.41a4.32,4.32,0,0,0,0,6.1,4.31,4.31,0,0,0,7.36-3,4.24,4.24,0,0,0-1.26-3.05A4.3,4.3,0,0,0,9,7.41Zm4.69,4.68a2.33,2.33,0,1,1,.67-1.63A2.33,2.33,0,0,1,13.64,12.09Z"
                    fill="#B0E41C"
                  ></path>
                </svg>
              </div>
            </template>
            <template slot="startDate" slot-scope="row">
              <div>{{ convertedDateTime(row.startDate) }}</div>
            </template>
            <template slot="endDate" slot-scope="row">
              <div>{{ convertedDateTime(row.endDate) }}</div>
            </template>
            <template slot="uploadBtn" slot-scope="row">
              <div>
                <router-link :to="'/offer-edit/' + row.id">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="fill-gray-7"
                      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                    />
                    <path
                      class="fill-gray-7"
                      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                    />
                  </svg>
                </router-link>
                <a-button type="link" :data-id="row.key">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-text"
                    viewBox="0 0 16 16"
                    @click="showDetailsModal(row.details)"
                  >
                    <path
                      d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"
                    />
                    <path
                      d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"
                    />
                  </svg>
                </a-button>
                <a-switch
                  v-model:checked="row.status"
                  @change="statusChange(row.id, row.status)"
                />
              </div>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <!-- / Projects Table Column -->

      <a-modal
        v-model:detailsModal="detailsModal"
        title="Offer Details"
        :confirm-loading="confirmLoading"
        :width="1000"
        @ok="closeDetailsModal"
      >
        <div v-if="Object.keys(offerDetails).length" class="new-upload-data">
          <div>
            <div>
              <span class="font-bold">Category Name (En): </span
              >{{ offerDetails.category.name.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Category Name (Bn): </span
              >{{ offerDetails.category.name.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Partner Name (En): </span>
              {{ offerDetails.partner.name.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Partner Name (Bn): </span>
              {{ offerDetails.partner.name.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Package Name (En): </span>
              {{ offerDetails.package.name.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Package Name (Bn): </span>
              {{ offerDetails.package.name.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Title (En): </span>
              {{ offerDetails.offer_title.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Title (Bn): </span>
              {{ offerDetails.offer_title.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Description (En): </span>
              {{ offerDetails.description.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Description (Bn): </span>
              {{ offerDetails.description.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Information Title (En): </span>
              {{ offerDetails.information.en.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Information Details (En): </span>
              <div v-html="offerDetails.information.en.details"></div>
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Information Title (Bn): </span>
              {{ offerDetails.information.bn.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Offer Information Details (Bn): </span>
              <div v-html="offerDetails.information.bn.details"></div>
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Avail Offer Title (En): </span>
              {{ offerDetails.avail_offer.en.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Avail Offer Details (En): </span>
              <div v-html="offerDetails.avail_offer.en.details"></div>
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Avail Offer Title (Bn): </span>
              {{ offerDetails.avail_offer.bn.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Avail Offer Details (Bn): </span>
              <div v-html="offerDetails.avail_offer.bn.details"></div>
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Terms and Conditions (En): </span>
              {{ offerDetails.term_and_condition.en }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Terms and Conditions (Bn): </span>
              {{ offerDetails.term_and_condition.bn }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Contact Information Title (En): </span>
              {{ offerDetails.contact_info.en.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Contact Information Title (Bn): </span>
              {{ offerDetails.contact_info.bn.title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Address (En): </span>
              {{ offerDetails.contact_info.en.address[0].title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Address (Bn): </span>
              {{ offerDetails.contact_info.bn.address[0].title }}
            </div>
          </div>
          <div>
            <span class="font-bold">Address Icon: </span>
            <img
              :src="offerDetails.contact_info.en.address[0].icon"
              :alt="offerDetails.contact_info.en.address[0].title"
              height="30"
              width="30"
            />
          </div>
          <div>
            <div>
              <span class="font-bold">Phone Number (En): </span>
              {{ offerDetails.contact_info.en.address[1].title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Phone Number (Bn): </span>
              {{ offerDetails.contact_info.bn.address[1].title }}
            </div>
          </div>
          <div>
            <span class="font-bold">Phone Number Icon: </span>
            <img
              :src="offerDetails.contact_info.en.address[1].icon"
              :alt="offerDetails.contact_info.en.address[1].title"
              height="30"
              width="30"
            />
          </div>
          <div>
            <div>
              <span class="font-bold">Email (En): </span>
              {{ offerDetails.contact_info.en.address[2].title }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Email (Bn): </span>
              {{ offerDetails.contact_info.bn.address[2].title }}
            </div>
          </div>
          <div>
            <span class="font-bold">Email Icon: </span>
            <img
              :src="offerDetails.contact_info.en.address[2].icon"
              :alt="offerDetails.contact_info.en.address[2].title"
              height="30"
              width="30"
            />
          </div>
          <div>
            <div>
              <span class="font-bold">Start Date: </span>
              {{ convertedDateTime(offerDetails.start_date) }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">End Date: </span>
              {{ convertedDateTime(offerDetails.end_date) }}
            </div>
          </div>
          <div v-for="(fac, index) in offerDetails.facility.bn">
            <div>
              <div>
                <span class="font-bold"
                  >Facility {{ index + 1 }} Title (En):
                </span>
                {{ offerDetails.facility.en[index].title }}
              </div>
            </div>
            <div>
              <div>
                <span class="font-bold"
                  >Facility {{ index + 1 }} Title (Bn):
                </span>
                {{ offerDetails.facility.bn[index].title }}
              </div>
            </div>
            <div>
              <span class="font-bold">Facility {{ index + 1 }} Icon: </span>
              <img
                :src="offerDetails.facility.en[index].icon"
                :alt="offerDetails.facility.en[index].title"
                height="30"
                width="30"
              />
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Weight: </span> {{ offerDetails.weight }}
            </div>
          </div>
          <div>
            <div>
              <span class="font-bold">Discount Amount: </span>
              {{ offerDetails.discount_amount }}
            </div>
          </div>
        </div>
      </a-modal>
    </a-row>
    <a-row :gutter="24" type="flex" justify="end" class="center">
      <a-pagination
        v-model:current="current"
        :total="total"
        :page-size="perPage"
        :page-size-options="pageSizeOptions"
        @change="change($event)"
        @showSizeChange="showSizeChange"
        show-size-changer
        show-quick-jumper
      />
    </a-row>

    <a-modal
      width="700px"
      v-model:locationModal="locationModal"
      title="Location List"
      @ok="closeLocationModal"
      @cancel="closeLocationModal"
    >
      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <!-- <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Location List
                  <a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
            </a-row>
          </template> -->
          <a-table
            :columns="locationColumnData"
            :data-source="locations"
            :pagination="false"
            :scroll="{ x: true }"
          >
            <template slot="status" slot-scope="row">
              <div>{{ row.status ? "Active" : "Inactive" }}</div>
            </template>
            <template slot="location" slot-scope="row">
              <div style="cursor: pointer">
                <svg
                  width="30"
                  height="30"
                  style="color: #b0e41c"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M18,4.48a8.45,8.45,0,0,0-12,12l5.27,5.28a1,1,0,0,0,1.42,0L18,16.43A8.45,8.45,0,0,0,18,4.48ZM16.57,15,12,19.59,7.43,15a6.46,6.46,0,1,1,9.14,0ZM9,7.41a4.32,4.32,0,0,0,0,6.1,4.31,4.31,0,0,0,7.36-3,4.24,4.24,0,0,0-1.26-3.05A4.3,4.3,0,0,0,9,7.41Zm4.69,4.68a2.33,2.33,0,1,1,.67-1.63A2.33,2.33,0,0,1,13.64,12.09Z"
                    fill="#B0E41C"
                  ></path>
                </svg>
              </div>
            </template>
            <template slot="startDate" slot-scope="row">
              <div>{{ convertedDateTime(row.startDate) }}</div>
            </template>
            <template slot="endDate" slot-scope="row">
              <div>{{ convertedDateTime(row.endDate) }}</div>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <!-- / Projects Table Column -->
    </a-modal>
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";
import moment from "moment";

const COLUMN_DATA = [
  {
    title: "Name (English)",
    dataIndex: "nameEn",
    class: "font-semibold text-muted",
  },
  {
    title: "Name (Mayanmar)",
    dataIndex: "nameMy",
    class: "font-semibold text-muted",
  },
  {
    title: "Start Date",
    scopedSlots: { customRender: "startDate" },
    class: "font-semibold text-muted",
  },
  {
    title: "End Date",
    scopedSlots: { customRender: "endDate" },
    class: "font-semibold text-muted",
  },
  {
    title: "Chances",
    dataIndex: "chances",
    class: "font-semibold text-muted",
  },
  {
    title: "Status",
    scopedSlots: { customRender: "status" },
    class: "font-semibold text-muted",
  },
  {
    title: "Location",
    scopedSlots: { customRender: "location" },
    class: "font-semibold text-muted",
  },
  {
    title: "Action",
    scopedSlots: { customRender: "uploadBtn" },
    width: 300,
  },
];

const LOCATION_COLUMN_DATA = [
  {
    title: "Name (English)",
    dataIndex: "nameEn",
    class: "font-semibold text-muted",
  },
  {
    title: "Name (Mayanmar)",
    dataIndex: "nameMy",
    class: "font-semibold text-muted",
  },
  {
    title: "Address",
    dataIndex: "address",
    class: "font-semibold text-muted",
  },
  {
    title: "Point Name",
    dataIndex: "pointName",
    class: "font-semibold text-muted",
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    class: "font-semibold text-muted",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    class: "font-semibold text-muted",
  },
  {
    title: "Minimum reach time",
    dataIndex: "minimumReachTime",
    class: "font-semibold text-muted",
  },
];
export default {
  name: "offer",
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      },
      columnData: COLUMN_DATA,
      locationColumnData: LOCATION_COLUMN_DATA,
      confirmLoading: false,
      walkathons: [],
      walkathonData: [],
      locations: [],
      locationModal: false,

      //Create New walkathon

      //Delete after complete
      offers: [],
      offerData: [],

      current: 1,
      perPage: 20,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      total: 2,
      detailsModal: false,
      offerDetails: {},
    };
  },
  created() {
    this.getWalkathonList();
  },
  methods: {
    convertedDateTime(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    change(current) {
      this.current = current;
      this.getWalkathonList();
    },
    showSizeChange(current, pageSize) {
      this.perPage = pageSize;
      this.current = current;
      this.getWalkathonList();
    },
    getWalkathonList() {
      this.confirmLoading = true;
      sendRequest(
        "get",
        `admin/walkathons?page=${this.current}&per_page=${this.perPage}&include=locations`,
        {},
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            this.walkathons = response.data.walkathons;
            for (const [key, value] of Object.entries(this.walkathons)) {
              this.walkathonData.push({
                key: key + 1,
                id: value.id,
                nameEn: value.name.en,
                nameMy: value.name.my,
                chances: value.chances,
                startDate: value.start_date,
                endDate: value.end_date,
                status: !!value.status,
                locations: value.locations,
                details: value,
              });
            }

            let pagination = response.data.meta.pagination;
            this.total = pagination.total;
            this.current = pagination.current_page;
          } else {
            if (response.status_code === 422) {
              message.error({
                content: () => "Please provide valid page size number",
                class: "custom-class",
                style: {
                  marginTop: "20vh",
                },
              });
            }
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    statusChange(offer_id, offer_status) {
      sendRequest(
        "post",
        "admin/offers/status-change",
        {
          offer_id: offer_id,
          status: Number(offer_status),
        },
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;
          message.success({
            content: () => "Offer Status Updated",
            class: "custom-class",
            style: {
              marginTop: "20vh",
            },
          });
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    showDetailsModal(offer) {
      this.offerDetails = offer;
      this.detailsModal = true;
    },
    closeDetailsModal() {
      this.offerDetails = {};
      this.detailsModal = false;
    },

    showLocationModal(locations) {
      for (const [key, value] of Object.entries(locations)) {
        this.locations.push({
          key: key + 1,
          id: value.id,
          nameEn: value.name.en,
          nameMy: value.name.my,
          address: value.address,
          pointName: value.point_name,
          latitude: value.latitude,
          longitude: value.longitude,
          minimumReachTime: value.minimum_reach_time,
          status: !!value.status,
        });
      }

      this.locationModal = true;
    },
    closeLocationModal() {
      this.locations = [];
      this.locationModal = false;
    },
  },

  computed: {
    walkathonList: function () {
      return this.walkathonData;
    },
  },
};
</script>
